import { intervalToDuration } from 'date-fns'

export default (timestamp: string) => {
  const timestampToDate = new Date(timestamp)
  const today = new Date()
  const duration = intervalToDuration({ start: timestampToDate, end: today })
  const keys = Object.keys(duration)
  const values = Object.values(duration)

  const findFirstNonZeroValueIndex = values.findIndex((x) => x !== undefined && x !== 0)
  const findKey = keys[findFirstNonZeroValueIndex]
  const findValue = values[findFirstNonZeroValueIndex]

  // remove plural s if value is 1
  return `${findValue} ${findValue === 1 ? findKey.slice(0, -1) : findKey} ago`
}
